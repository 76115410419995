<template>
  <div>
    <el-input
      v-if="isEditRelativeUrl"
      v-model="editedRelativeUrl"
    />
    <span
      v-if="!isEditRelativeUrl"
      style="margin-right: 10px"
    >
      {{ relativeUrl || '[not set]' }}
    </span>

    <el-button
      v-if="!isEditRelativeUrl"
      size="mini"
      @click="editRelativeUrl"
    >
      Edit
    </el-button>

    <span
      v-if="isEditRelativeUrl"
    >
      <el-button
        size="mini"
        type="success"
        @click="setRelativeUrl"
      >
        OK
      </el-button>
      <el-button
        size="mini"
        @click="cancelEditRelativeUrl"
      >
        Cancel
      </el-button>
      <el-button
        size="mini"
        type="warning"
        @click="handleRelativeUrl"
      >
        Generate
      </el-button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'RelativeUrl',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isEditRelativeUrl: false,
      relativeUrl: this.value,
      editedRelativeUrl: '',
      edited: false,
    };
  },
  watch: {
    value(newVal) {
      if (this.edited) {
        return;
      }

      this.relativeUrl = newVal;
      this.editedRelativeUrl = newVal;
    },
    relativeUrl(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    handleRelativeUrl() {
      this.$emit('generate-relative-url', this.isEditRelativeUrl);
      // this.edited = false;
      this.isEditRelativeUrl = false;
    },
    editRelativeUrl() {
      this.isEditRelativeUrl = true;
      this.editedRelativeUrl = this.relativeUrl;
    },
    setRelativeUrl() {
      if (this.editedRelativeUrl && this.editedRelativeUrl.trim().length) {
        this.relativeUrl = this.editedRelativeUrl;
        this.$emit('set-relative-url', this.relativeUrl);
      }

      this.isEditRelativeUrl = false;
      // this.edited = true;
    },
    cancelEditRelativeUrl() {
      this.isEditRelativeUrl = false;
    },
  },
};
</script>
