<template>
  <div class="flex-form">
    <el-form
      ref="categoryForm"
      v-loading="loading"
      :model="categoryForm"
      label-width="170px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Category
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Category
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Uid"
      >
        {{ categoryUid }}
      </el-form-item>

      <el-form-item
        label="Active"
        prop="active"
      >
        <el-switch
          v-model="categoryForm.isActive"
        />
      </el-form-item>

      <el-form-item
        label="Position"
        prop="position"
      >
        <el-input v-model="categoryForm.position" />
      </el-form-item>

      <el-tabs
        v-model="activeLang"
        type="card"
        closable
        @edit="handleTabsEdit"
      >
        <el-tab-pane
          v-for="(item, index) in categoryForm.content"
          :key="item.index"
          :label="langName(item.langCode)"
          :name="item.langCode"
        >
          <el-form-item
            :label="`Name [${item.langCode}]`"
            :prop="'content.' + index + '.name'"
          >
            <el-input
              v-model="categoryForm.content[index].name"
              @input="handleNameInput(index, item.langCode)"
            />
          </el-form-item>

          <el-form-item
            :label="`Relative Url [${item.langCode}]`"
            :prop="'content.' + index + '.relativeUrl'"
          >
            <RelativeUrl
              v-model="categoryForm.content[index].relativeUrl"
              @generate-relative-url="handleRelativeUrl($event, index, categoryForm.content[index].name)"
              @set-relative-url="setRelativeUrl($event, index)"
            />
          </el-form-item>

          <el-form-item
            :label="`Description [${item.langCode}]`"
            :prop="'content.' + index + '.description'"
          >
            <el-input
              v-model="categoryForm.content[index].description"
              type="textarea"
              resize="none"
              :autosize="{ minRows: 2, maxRows: 8}"
            />
          </el-form-item>

          <el-form-item
            :label="`Meta Title [${item.langCode}]`"
            :prop="'content.' + index + '.metaTitle'"
          >
            <el-input v-model="categoryForm.content[index].metaTitle" />
          </el-form-item>

          <el-form-item
            :label="`Meta Description [${item.langCode}]`"
            :prop="'content.' + index + '.metaDescription'"
          >
            <el-input
              v-model="categoryForm.content[index].metaDescription"
              type="textarea"
              resize="none"
              :autosize="{ minRows: 2, maxRows: 8}"
            />
          </el-form-item>
        </el-tab-pane>
      </el-tabs>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getPostCategory,
  updatePostCategory,
  createPostCategory,
  generateRelativeUrl,
} from '@/api/postCategory';
import { getAllLanguages } from '@/api/language';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import RelativeUrl from '@/components/RelativeUrl';

export default {
  name: 'PostCategoryDetails',
  components: {
    RelativeUrl,
  },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    categoryUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      categoryForm: {
        content: [],
        position: 0,
        isActive: true,
      },
      category: {},
      langOptions: [],
      activeLang: '',
      relativeUrlEdited: {},
      rules: {
        position: [{
          required: true,
          trigger: 'blur',
          message: 'Position is required',
        }],
      },
    };
  },
  watch: {
    visible() {
      if (this.categoryUid) {
        this.getCategory(this.categoryUid);
      } else {
        this.initForm();
      }
    },
  },
  async created() {
    if (this.isEditMode) {
      await this.getCategory(this.categoryUid);
    } else {
      await this.initForm();
    }
  },
  methods: {
    async initForm() {
      this.resetForm();

      await this.getLanguages();

      this.langOptions.forEach((item) => {
        this.categoryForm.content.push({
          langCode: item.value,
          name: '',
          relativeUrl: '',
          description: '',
          metaTitle: '',
          metaDescription: '',
        });
      });

      this.categoryForm.content.forEach((item, index) => {
        this.handleContentRules(index, item.langCode, item.name);
      });

      this.activeLang = this.langOptions[0].value;
    },
    langName(code) {
      const langName = this.langOptions.find((item) => item.value === code)?.label;
      const catName = this.categoryForm.content.find((item) => item.langCode === code)?.relativeUrl;

      if (!catName.trim()) {
        return `! ${langName}`;
      }

      return langName;
    },
    async getCategory(categoryUid) {
      try {
        this.resetForm();
        this.loading = true;

        await this.getLanguages();

        const data = await getPostCategory({ categoryUid });

        this.category = cloneDeep(data.category);
        this.categoryForm = data.category;

        // order content by langOptions
        this.categoryForm.content = this.langOptions.map((item) => {
          const content = this.categoryForm.content.find((c) => c.langCode === item.value);
          if (!content) {
            return {
              langCode: item.value,
              name: '',
              relativeUrl: '',
              description: '',
              metaTitle: '',
              metaDescription: '',
            };
          }

          return content;
        });

        this.activeLang = this.categoryForm.content
          .find((item) => item.name.trim())?.langCode || this.langOptions[0].value;

        this.categoryForm.content.forEach((item, index) => {
          this.handleContentRules(index, item.langCode, item.name);
        });

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.onCancel();

        throw e;
      }
    },
    async onEditSubmit() {
      this.$refs.categoryForm.validate(async (valid, errors) => {
        if (valid) {
          const updatedCategory = makeUpdateObj(this.category, this.categoryForm);
          if (Object.keys(updatedCategory).length > 0) {
            try {
              this.saving = true;
              updatedCategory.categoryUid = this.categoryUid;

              await updatePostCategory(updatedCategory);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              this.saving = false;

              this.$emit('update');
              this.close();
            } catch (e) {
              // await this.getCategory(this.categoryUid);

              this.saving = false;
            }
          }
        } else {
          this.$message({
            type: 'error',
            message: errors[Object.keys(errors)[0]][0].message,
          });
        }
      });
    },
    async onAddSubmit() {
      this.$refs.categoryForm.validate(async (valid, errors) => {
        if (valid) {
          try {
            this.saving = true;
            await createPostCategory(this.categoryForm);

            this.$message({
              type: 'success',
              message: 'Category has been added successfully',
            });

            // this.resetForm();
            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        } else {
          this.$message({
            type: 'error',
            message: errors[Object.keys(errors)[0]][0].message,
          });
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    async getLanguages() {
      try {
        const data = await getAllLanguages();
        this.langOptions = data.languages.map((item) => ({
          value: item.langCode,
          label: item.langName,
        }));
      } catch (e) {
        this.langOptions = [];
      }
    },
    resetForm() {
      this.category = {};
      this.categoryForm.content = [];
      this.relativeUrlEdited = {};

      this.$nextTick(() => {
        this.$refs.categoryForm.resetFields();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    handleContentRules(index, lang, value) {
      if (value.trim()) {
        this.addContentRules(index, lang);
      } else {
        this.removeStaticPageRules(index);
      }
    },
    addContentRules(index, lang) {
      if (this.rules[`content.${index}.name`] || index < 0) {
        return;
      }

      this.$set(this.rules, `content.${index}.name`, [{
        required: true,
        trigger: 'blur',
        message: `Name [${lang}] is required`,
      }]);
      this.$set(this.rules, `content.${index}.relativeUrl`, [{
        required: true,
        trigger: 'blur',
        message: `Relative Url [${lang}] is required`,
      }]);

      setTimeout(() => {
        this.$refs.categoryForm.clearValidate();
      });
    },
    removeStaticPageRules(index) {
      this.$delete(this.rules, `content.${index}.name`);
      this.$delete(this.rules, `content.${index}.relativeUrl`);
    },
    handleTabsEdit(targetName) {
      // remove content
      const index = this.categoryForm.content.findIndex((item) => item.langCode === targetName);

      this.categoryForm.content[index].name = '';
      this.categoryForm.content[index].relativeUrl = '';
      this.categoryForm.content[index].description = '';
      this.categoryForm.content[index].metaTitle = '';
      this.categoryForm.content[index].metaDescription = '';

      this.removeStaticPageRules(index);
    },
    async handleRelativeUrl(editRelativeUrl, index, value) {
      if (this.category?.content?.[index]?.relativeUrl && !editRelativeUrl) {
        return;
      }

      if (value && value.trim().length) {
        const data = await generateRelativeUrl({
          name: value,
          categoryUid: this.categoryUid,
          langCode: this.categoryForm.content[index].langCode,
        });

        if (data.relativeUrl) {
          this.categoryForm.content[index].relativeUrl = data.relativeUrl;
        }
      }
    },
    handleNameInput(index, langCode) {
      this.handleContentRules(index, langCode, this.categoryForm.content[index].name);
      if (!this.relativeUrlEdited[index]) {
        this.handleRelativeUrl(false, index, this.categoryForm.content[index].name);
      }
    },
    setRelativeUrl(value, index) {
      this.relativeUrlEdited[index] = true;
      this.handleRelativeUrl(true, index, value);
    },
  },
};
</script>
