import Vue from 'vue';

export function listLanguages(params) {
  return Vue.$http({
    url: '/languages/listLanguages',
    method: 'get',
    params,
  });
}

export function getLanguage(params) {
  return Vue.$http({
    url: '/languages/getLanguage',
    method: 'get',
    params,
  });
}

export function updateLanguage(data) {
  return Vue.$http({
    url: '/languages/updateLanguage',
    method: 'post',
    data,
  });
}

export function createLanguage(data) {
  return Vue.$http({
    url: '/languages/createLanguage',
    method: 'post',
    data,
  });
}

export function deleteLanguage(data) {
  return Vue.$http({
    url: '/languages/deleteLanguage',
    method: 'post',
    data,
  });
}

export function getAllLanguages(params) {
  return Vue.$http({
    url: '/languages/getAllLanguages',
    method: 'get',
    params,
  });
}
