import Vue from 'vue';

export function listPostCategories(params) {
  return Vue.$http({
    url: '/postCategories/listPostCategories',
    method: 'get',
    params,
  });
}

export function getPostCategory(params) {
  return Vue.$http({
    url: '/postCategories/getPostCategory',
    method: 'get',
    params,
  });
}

export function getAllPostCategories(params) {
  return Vue.$http({
    url: '/postCategories/getAllPostCategories',
    method: 'get',
    params,
  });
}

export function updatePostCategory(data) {
  return Vue.$http({
    url: '/postCategories/updatePostCategory',
    method: 'post',
    data,
  });
}

export function createPostCategory(data) {
  return Vue.$http({
    url: '/postCategories/createPostCategory',
    method: 'post',
    data,
  });
}

export function deletePostCategory(data) {
  return Vue.$http({
    url: '/postCategories/deletePostCategory',
    method: 'post',
    data,
  });
}

export function generateRelativeUrl(params) {
  return Vue.$http({
    url: '/postCategories/generateRelativeUrl',
    method: 'get',
    params,
  });
}
